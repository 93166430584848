<template>
  <div>
    <el-form inline class="mb-20">
      <el-form-item label="状态">
        <el-select placeholder="请选择话题" v-model="form.examine_status">
          <el-option label="待审核" :value="0"/>
          <el-option label="通过" :value="10"/>
          <el-option label="不通过" :value="20"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table class="mb-20" stripe border :data="list">
      <!--      <el-table-column align="center" prop="number" label="学号"/>-->
      <el-table-column align="center" prop="id" label="认证ID"/>
      <el-table-column align="center" prop="user_id" label="用户ID"/>
      <el-table-column align="center" prop="nickName" label="学生证" >
        <template #default="{row, $index}">
          <el-image
            style="width: 100px;height: 100px;margin-right: 10px;"
            :src="row.st_idcard_front"
            :preview-src-list="[row.st_idcard_front]"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="user.wechat" label="微信号"/>
      <el-table-column align="center" prop="user.phone" label="手机号"/>
      <el-table-column align="center" label="操作">
        <template #default="{row, $index}">
          <!--          <el-button type="text" @click="showDetailBox">查看详情</el-button>-->

          <el-popconfirm title="是否确定通过?" confirmButtonText="确定" cancelButtonText="取消" @confirm="examine(row, 10)">
            <template #reference>
              <el-button type="text">通过</el-button>
            </template>
          </el-popconfirm>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="是否确定不通过?" confirmButtonText="确定" cancelButtonText="取消" @confirm="examine(row, 20)">
            <template #reference>
              <el-button type="text">不通过</el-button>
            </template>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :page-sizes="params.per_page"
          :current-page="params.page"
          :page-size="10"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { mapState } from 'vuex'
export default {
  name: 'User',
  data() {
    return {
      params: {
        page: 1,
        per_page: 10,
        search: '',
      },
      total: 1,
      list: [],
      detailBox: false,
      form: {
        examine_status: 0
      },
    };
  },
  created() {
    this.getList(1)
  },
  computed: {
    ...mapState(['globalData'])
  },
  methods: {
    showDetailBox() {
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.wxuserExamine.page({
        page: page || this.params.page,
        examine_status: this.form.examine_status,
      }).then((res) => {
        // console.log(res);
        // console.log(that.$store.state);
        let list = res.data.data;
        list.forEach(item => {
          item.st_idcard_front = that.$store.state.globalData.cos_domain + item.st_idcard_front
        })
        that.list = list;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    submit() {
      this.getList(1)
    },
    reset() {
      this.params.examine_status = '';
      this.submit()
    },
    changePage(page) {
      this.getList(page)
    },
    examine(data, examine_status) {
      this.$api.wxuserExamine.examine({
        id: data.id,
        examine_status,
      }).then(res => {
        this.getList()
      })
    }
  },
};
</script>
