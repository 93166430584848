<template>
  <div>
    <div class="right-box">
      <el-button type="primary" @click="showDetailBox(null)">添加上课时间段</el-button>
    </div>
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="id" label="ID"/>
      <el-table-column align="center" prop="term.text" label="学期"/>
      <el-table-column align="center" label="操作">
        <template #default="{row}">
          <template v-if="row.is_confirm == 0">
            <el-popconfirm title="确认之后课表将无法修改" confirmButtonText="确认" cancelButtonText="取消" @confirm="confirm(row)">
              <template #reference>
                <el-button type="text">确认课表</el-button>
              </template>
            </el-popconfirm>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="showDetailBox(row)">修改</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">
              <template #reference>
                <el-button type="text">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
          <template v-else-if="row.is_confirm == 1">
            <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :page-sizes="params.per_page"
          :current-page="params.page"
          :page-size="10"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      title="添加上课时间段"
      width="700px"
    >
      <el-form label-width="100px" :disabled="form.is_confirm">
<!--        <el-form-item label="上课时间段">-->
<!--          <el-date-picker-->
<!--            v-model="time"-->
<!--            type="daterange"-->
<!--            range-separator="至"-->
<!--            start-placeholder="开始时间"-->
<!--            end-placeholder="结束时间"-->
<!--          />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="每天上课节数">-->
<!--          <el-input v-model="classNumber" placeholder="请输入每天上课节数"/>-->
<!--        </el-form-item>-->
        <el-form-item label="学年" label-width="180px">
          <el-select v-model="form.years_id" class="m-2" placeholder="请选择学年" size="large">
            <el-option
              v-for="item in years_list"
              :key="item.id"
              :label="item.school_year"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学期" label-width="180px">
          <el-radio-group v-model="form.term">
            <el-radio :label="1">第一学期</el-radio>
            <el-radio :label="2">第二学期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="第一周星期一日期" label-width="180px">
          <el-date-picker v-model="form.first_week_monday" type="date" placeholder="请选择每天上课节数">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="总周数" label-width="180px">
          <el-input v-model="form.week_amount" placeholder="请输入总周数"/>
        </el-form-item>
        <el-form-item label="节次" label-width="180px">
          <template v-for="(item, index) in form.time_slot">
            <el-form-item :label="'第' + item.section + '节'" label-width="60px" style="margin-bottom: 10px;">
              <el-time-picker
                is-range
                arrow-control
                v-model="item.time"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="HH:mm"
                :editable="false"
              />
              <el-icon size="18" style="margin-left: 10px;" @click="delTime(index)" v-if="index == form.time_slot.length - 1"><circle-close-filled /></el-icon>
            </el-form-item>
          </template>
          <el-button style="width: 100%" @click="addTime"><el-icon><plus /></el-icon>添加</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <div>
          <el-button type="primary" @click="submit" v-if="form.is_confirm == 0">保存</el-button>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// years_id           学年id
// term                学期(第一学期：1，第二学期：20)
// first_week_monday   第一周星期一日期
// week_amount         总周数
// time_slot[n][section]          节次(n为二维数组索引)
// time_slot[n][start_clock]      上课点数(n为二维数组索引,示例值：8:00)
// time_slot[n][end_clock]        下课点数(n为二维数组索引)

import {ElMessage} from "element-plus";
import moment from 'moment';
export default {
  name: 'Courses',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      time: [],
      classNumber: '',
      years_list: [],
      form: {
        years_id: '',
        term: 1,
        first_week_monday: '',
        week_amount: '',
        time_slot: [],
        is_confirm: 0,
      }
    };
  },
  created() {
    this.$api.courseYears.list().then((res) => {
      this.years_list = res.data;
    });
    this.getList(1)
  },
  methods: {
    showDetailBox(data) {
      if(data) {
        this.form.id = data?.id
        this.form.years_id = data?.years_id
        this.form.term = data?.term.value
        this.form.is_confirm = data?.is_confirm
        this.form.first_week_monday = data?.first_week_monday
        this.form.week_amount = data?.week_amount
        let time_slot = data?.courseSlot;
        time_slot?.length != 0 && time_slot.forEach(item => {
          item.time = ['2022/1/1 ' + item.start_clock, '2022/1/1 ' + item.end_clock]
        })
        this.form.time_slot = time_slot
      } else {
        if(this.form.id) delete this.form.id;
        this.form.years_id = '',
        this.form.term = 1
        this.form.first_week_monday = ''
        this.form.week_amount = ''
        this.form.time_slot = []
      }
      this.detailBox = true;
    },
    hideDetailBox() {
      delete this.form.id
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.courseSetting.page({
        page: page || this.params.page,
      }).then((res) => {
        // console.log(res);
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    changePage(page) {
      this.getList(page)
    },
    confirm(data) {
      let that = this;
      this.$api.courseSetting.confirm({
        id: data.id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    del(data) {
      let that = this;
      this.$api.courseSetting.del({
        id: data.id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    submit() {
      let form = this.form;
      let that = this;
      form.time_slot.forEach(item => {
        item.start_clock = moment(item.time[0]).format('HH:mm')
        item.end_clock = moment(item.time[1]).format('HH:mm')
        delete item.time
      })
      form.first_week_monday = moment(form.first_week_monday).format('YYYY-MM-DD')
      if (form.id) {
        this.$api.courseSetting.edit({
          ...form,
        }).then(res => {
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          that.getList()
          that.hideDetailBox()
        })
      } else {
        this.$api.courseSetting.add({
          ...form,
        }).then(res => {
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          that.getList()
          that.hideDetailBox()
        })
      }
    },
    addTime() {
      this.form.time_slot.push({
        section: this.form.time_slot.length + 1,
        start_clock: '',
        end_clock: '',
        time: [new Date(), new Date()],
      })
    },
    delTime(index) {
      this.form.time_slot.splice(index, 1)
    }
  },
};
</script>

<style scoped>

</style>
